<br />
<div class="row">
  <div class="col-lg-8 offset-lg-2" id="question-list">
    <h3 class="text-center">{{ qCount }} Questions So Far:</h3>

    <div
      class="alert alert-success fade show"
      role="alert"
      *ngIf="submitted"
      style="margin-top: 0.5rem;"
    >
      Thank you for submitting your question to the Government. Please vote
      below for the other questions you would most like to have answered.
    </div>

    <!-- Button trigger modal -->
    <br />

    <div class="text-center">
      <div
        class="btn-group btn-group-sm"
        role="group"
        aria-label="Basic example"
      >
        <button type="button" class="btn btn-primary" (click)="swap('best_all')"  [ngClass]="currentMode == 'best_all' ? 'active' : ''">
          Most Upvoted (All Time)
        </button>
        <button type="button" class="btn btn-danger" (click)="swap('best_today')"  [ngClass]="currentMode == 'best_today' ? 'active' : ''">
          Most Upvoted (Today)
        </button>
        <button type="button" class="btn btn-success" (click)="swap('latest')" [ngClass]="currentMode == 'latest' ? 'active' : ''">
          Latest Questions
        </button>
      </div>
    </div>
    <br />

    <div *ngFor="let question of currentQuestions | async">
      <div class="row question"
        *ngIf="(question.title.length > 0) && (currentMode == 'best_all' || currentMode == 'latest' || isToday(question.asked_at))">
        <div class="col-md-1 text-center">
          <div [ngSwitch]="question.category">
            <span class="material-icons cat-icon" *ngSwitchCase="'Health'">
              local_hospital
            </span>
            <span class="material-icons cat-icon" *ngSwitchCase="'Economy'">
              attach_money
            </span>
            <span
              class="material-icons cat-icon"
              *ngSwitchCase="'Public Order'"
            >
              gavel
            </span>
            <span class="material-icons cat-icon" *ngSwitchCase="'Other'">
              feedback
            </span>
          </div>
          <span class="question-upvotes badge badge-pill badge-primary">
            {{ question.upvotes }}
          </span>
        </div>
        <div class="col-md">
          <h4 class="question-title">
            {{ question.title }}
          </h4>
          <p class="question-date" *ngIf="question.asked_at">
            Asked by {{ question.first_name_asker }} at
            {{ question.asked_at.toDate() | date: "shortTime" }} on
            {{ question.asked_at.toDate() | date: "EEEE, MMMM d" }}
          </p>
        </div>
        <div class="col-md-2">
          <button
            class="btn btn-danger btn-block"
            role="button"
            (click)="upvote(question.id, $event)"
            *ngIf="button_active(question.id)"
          >
            <span class="material-icons">
              favorite
            </span>
            UPVOTE
          </button>
          <div
            *ngIf="!button_active(question.id)"
            class="text-center bg-success text-white py-2 rounded"
          >
            <span class="material-icons cat-icon">done</span>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center">
      <button
        type="button"
        class="btn btn-primary btn-lg"
        data-toggle="modal"
        data-target="#create_modal"
      >
        Submit a question
      </button>
    </div>
  </div>
</div>

<!-- Modal -->
<form [formGroup]="questionForm" (ngSubmit)="ask_question(questionForm.value)">
  <div
    class="modal fade"
    id="create_modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="create_modal"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Submit a question</h5>
          <button
            type="button"
            class="close"
            #modal_close
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <textarea
            class="form-control"
            rows="3"
            placeholder="Question for the Government"
            formControlName="title"
          ></textarea>
          <select
            class="form-control"
            required
            formControlName="category"
            style="margin-top: 0.5rem;"
          >
            <option disabled selected value="Other">
              -- select a category --
            </option>

            <option *ngFor="let cat of categories" [value]="cat">{{
              cat
            }}</option>
          </select>

          <hr />
          <div class="form-row">
            <div class="col">
              <input
                required
                type="text"
                class="form-control"
                placeholder="First name"
                formControlName="first_name_asker"
              />
            </div>
            <div class="col">
              <input
                required
                type="text"
                class="form-control"
                placeholder="Last name"
                formControlName="surname_asker"
              />
            </div>
          </div>
          <div class="form-row" style="margin-top: 0.5rem;">
            <div class="col-md-9">
              <input
                required
                type="email"
                class="form-control"
                placeholder="Email"
                formControlName="email_asker"
              />
            </div>
            <div class="col-md-3">
              <input
                required
                type="text"
                class="form-control"
                placeholder="Post code"
                formControlName="postcode_asker"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button type="submit" class="btn btn-primary">
            Submit&nbsp;+
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
