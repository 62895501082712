import { Component, ViewChild, ElementRef } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators'
import { FormBuilder } from '@angular/forms'
import 'firebase/firestore';
import { firestore } from 'firebase/app';
import * as firebase from 'firebase';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'Question Bank';
  categories = ["Economy", "Health", "Public Order", "Other"]
  submitted = false;
  currentMode = "best_today"
  questionForm;
  qCount;
  @ViewChild('modal_close') modal_close: ElementRef;

  questionsMost: Observable<any[]>;
  questionsLatest: Observable<any[]>;

  currentQuestions: Observable<any[]>

  constructor(private af: AngularFirestore, private formBuilder: FormBuilder) {
    this.questionsMost = af.collection('questions',
      ref => ref.orderBy('upvotes', 'desc')
        .limit(500))
      .valueChanges({ idField: 'id' });
    this.questionsLatest = af.collection('questions',
      ref => ref.orderBy('asked_at', 'desc')
        .limit(500))
      .valueChanges({ idField: 'id' });

    this.currentQuestions = this.questionsMost;

    this.questionForm = this.formBuilder.group({
      title: '',
      email_asker: '',
      category: '',
      first_name_asker: '',
      postcode_asker: '',
      surname_asker: ''
    });
    af.collection('questions').ref.get().then(snap =>
      this.qCount = snap.size
    )
    af.collection('draft_questions').ref.get().then(snap =>
      this.qCount += snap.size
    )
    af.collection('archived_questions').ref.get().then(snap =>
      this.qCount += snap.size
    )
  }

  swap(mode: string) {
    this.currentQuestions = (mode == "latest") ? this.questionsLatest : this.questionsMost;
    this.currentMode = mode;
  }

  ask_question(form_data) {
    var question_doc = form_data
    question_doc["asked_at"] = firebase.firestore.Timestamp.fromMillis(Date.now())
    question_doc["upvotes"] = 1
    this.af.collection('draft_questions').add(question_doc)
    this.modal_close.nativeElement.click()
    this.submitted = true;
  }
  upvote(id: string, ev: Event) {

    if (!+window.localStorage.getItem(`voted-${id}`)) {
      const increment = firestore.FieldValue.increment(1);
      const question = this.af.doc(`questions/${id}`);
      question.update({ upvotes: increment });
      window.localStorage.setItem(`voted-${id}`, '1');
    }

  }

  isToday(d) {
    const someDate = d.toDate()
    const today = new Date()
    return someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
  }


  button_active(id: string) {
    if (+window.localStorage.getItem(`voted-${id}`)) {
      return false
    } else {
      return true
    }
  }

}
