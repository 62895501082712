import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics'

const firebaseConfig = {
  apiKey: "AIzaSyBfhieWNC0jnL7WEA1vZYydSN4G2rDplz0",
  authDomain: "act-covid-questions.firebaseapp.com",
  databaseURL: "https://act-covid-questions.firebaseio.com",
  projectId: "act-covid-questions",
  storageBucket: "act-covid-questions.appspot.com",
  messagingSenderId: "212410333680",
  appId: "1:212410333680:web:411e57a3dbdf551747c535",
  measurementId: "G-DJRKF638KB"
};


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule,
    AngularFireAnalyticsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
